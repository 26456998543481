<template>
  <div class="Dashboard">
    <dashboard-top>
      <screen-title title="Invitations"></screen-title>
    </dashboard-top>
    <div class="container-full">
      <div class="dashboard__section">
        <section-title title="Your invitations" />
        <fade-transition mode="out-in">
          <TableLoader v-if="!loaded" />
          <UsersInvitationsTable
            :items="pending"
            :key="pending.length"
            v-if="loaded && pending.length > 0"
          />
          <empty-message v-if="loaded && pending.length == 0">
            No invitations were send.
          </empty-message>
        </fade-transition>
      </div>
      <div class="dashboard__section mt-48">
        <section-title title="Your Teams" />

        <fade-transition mode="out-in">
          <TableLoader v-if="!loaded" />
          <TeamsTable
            :items="teams"
            :key="teams.length"
            v-if="loaded && teams.length > 0"
          />
          <empty-message v-if="loaded && teams.length == 0">
            No selectors found, invite new ones.
          </empty-message>
        </fade-transition>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import UsersInvitationsTable from '@/components/Tables/UsersInvitationsTable';
  import TeamsTable from '@/components/Tables/TeamsTable';
  import TableLoader from '@/components/Loaders/TableLoader';
  export default {
    name: 'Invitations',
    components: { UsersInvitationsTable, TableLoader, TeamsTable },
    created() {
      this.fetchInvitations(this.user?.id);
    },

    methods: {
      ...mapActions({
        fetchInvitations: 'GET_INVITATIONS',
      }),
    },
    computed: {
      ...mapState({
        loaded: (state) => state.user.invitations.status,
        pending: (state) => state.user.invitations.pending,
        teams: (state) => state.user.teams,
        user: (state) => (state.user.profile ? state.user.profile : null),
      }),
    },
  };
</script>

<style lang="scss" scoped></style>
