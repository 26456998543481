<template lang="html">
  <div class="Table">
    <simplebar class="TableScrollable">
      <div class="TableScrollable__wrapper">
        <TableHeader
          @sortChanged="orderBy"
          :ordered-by="orderedBy"
          :columns="table.header"
        />

        <main class="TableBody">
          <div
            class="TableItem"
            v-for="item in chunkedList[listQuery.page - 1]"
            :key="item.id"
          >
            <div class="TableColumn TableColumn--large TableColumn--profile">
              <profile-pic :size="40" :src="item.photo_url" />
              <div class="ml-8">{{ item.name }}</div>
            </div>
            <div class="TableColumn TableColumn--flex">
              <tag-item>
                {{ item.pivot.role | capitalize }}
              </tag-item>
            </div>
            <div class="TableColumn TableColumn--medium">
              <div>
                <button
                  class="btn"
                  v-if="item.id !== currentTeam"
                  @click="switchTeam(item)"
                >
                  Switch to this team
                </button>
                <div class="url" v-else>Current team</div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </simplebar>

    <footer>
      <Pagination
        :total="this.total"
        :page="this.listQuery.page"
        :limit="this.listQuery.limit"
        @pagination="handlePagination"
        :pagination-history="paginationHistory"
      ></Pagination>
    </footer>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import TableMixin from '@/mixins/TableMixin';

  export default {
    name: 'TeamsTable',
    mixins: [TableMixin],
    data: function () {
      return {
        table: {
          header: [
            {
              label: 'Team',
              sorted: true,
              sortProp: 'name',
              size: 'large',
            },
            {
              label: 'Role',
              sorted: true,
              sortProp: 'pivot.role',
              size: 'flex',
            },
            {
              label: 'Actions',
              size: 'medium',
            },
          ],
        },
      };
    },
    methods: {
      ...mapActions({
        switchTeam: 'SWITCH_TEAM',
      }),
    },
    computed: {
      ...mapGetters({
        currentTeam: 'currentTeam',
      }),
    },
  };
</script>
